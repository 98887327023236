/* eslint-disable */
import { 
    yellowThemeDark
} from '../theme/theme'


export const themeData = {
    theme: yellowThemeDark
}


// Choose theme from above