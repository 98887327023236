export const experienceData = [
    {
        id: 1,
        company: 'Société Générale Maroc',
        jobtitle: 'Backend developer - PFE internship',
        startYear: '02/2024',
        endYear: '07/2024'
    },
    {
        id: 2,
        company: 'Webmarco',
        jobtitle: 'Microservices backend Developer - internship',
        startYear: '06/2023',
        endYear: '09/2023'
    },
    {
        id: 3,
        company: 'JDI soft',
        jobtitle: 'Front end developer - internship',
        startYear: '07/2022',
        endYear: '09/2022'
    },
]