import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Reporting Bank Al-Maghrib',
        projectDesc: 'Migrated Bank Al-Maghrib reporting application to a web platform, enhancing data management, report generation, and user interface for improved reporting efficiency.',
        tags: ['Spring modules', 'Apache POI', 'PostgreSql', 'Flyway'],
        code: '',
        demo: '',
        image: one
    },
    {
        id: 2,
        projectName: 'Gestion d inventaire',
        projectDesc: 'Digital management of material resources for ENSAF: A PFA school project for digital inventory optimization for the National School of Applied Sciences of Fez.',
        tags: ['React 18', 'ASP.NET Core 6.0', 'Entity Framework Core 6.0'],
        code: 'https://github.com/Boufnichel314/PFA_Backend_Spring_boot/tree/main/pfa',
        demo: 'https://www.youtube.com/watch?v=cQAP7Ohdvoo',
        image: five
    },
    {
        id: 3,
        projectName: 'Readme',
        projectDesc: 'Implementation of an online library "Readme" Design and development of a digital platform "Readme" facilitating the discovery and acquisition of books. Implementation of features for intuitive navigation and simplified access to literary resources.',
        tags: ['React 18', 'Google Books API', 'Material-UI'],
        code: 'https://github.com/Boufnichel314/ReadMe',
        demo: 'https://www.youtube.com/watch?v=5WdVGDoGykU',
        image: three
    },
    {
        id: 4,
        projectName: 'E-commerce en microsevices',
        projectDesc: 'PFA Internship: Implementation of a microservices architecture An automated system for order and product management, based on a microservices architecture (with synchronous communication)',
        tags: ['Spring modules', 'Flyway', 'Docker', 'JUnit'],
        code: 'https://github.com/Boufnichel314/Webmarko_facture',
        demo: 'https://www.youtube.com/watch?v=l9JJhbqaoOY',
        image: two
    },
    {
        id: 5,
        projectName: 'Application Web de la location de voitures',
        projectDesc: 'Design and development of a robust backend API for a vehicle rental platform. The project implements a complete MVC architecture, offering comprehensive management of customer reservations and payment processes.',
        tags: ['.NET 6', 'Entity Framework', 'ASP.NET Core Web API', 'SQL Server'],
        code: 'https://github.com/Boufnichel314/Cars_Back_dotNET',
        demo: 'https://www.youtube.com/watch?v=pmaw_3jP5UU',
        image: four
    },
    {
        id: 6,
        projectName: 'Application Web pour le groupe Moroccan MIX movies',
        projectDesc: 'Design and development of a web app for the facebook group Moroccan Mix. The project shows the best collections to watch, offering a good user interface experience.',
        tags: ['React', 'MAterial UI', 'ASP.NET Core Web API', 'SQL Server'],
        code: 'https://github.com/Boufnichel314/Cars_Back_dotNET',
        demo: 'https://www.youtube.com/watch?v=-wfiG6ZniG8',
        image: five
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/