import docker from '../assets/svg/skills/docker.svg'
import angular from '../assets/svg/skills/angular.svg'
import javascript from '../assets/svg/skills/javascript.svg'
import react from '../assets/svg/skills/reactjs.svg'
import mongoDB from '../assets/svg/skills/mongoDB.svg'
import postgresql from '../assets/svg/skills/postgresql.svg'
import csharp from '../assets/svg/skills/csharp.svg'
import java from '../assets/svg/skills/java.svg'
import git from '../assets/svg/skills/git.svg'
import spring from '../assets/svg/skills/spring.svg'
import springBoot from '../assets/svg/skills/spring.svg'
import hibernate from '../assets/svg/skills/hibernate.svg'
import dotnet from '../assets/svg/skills/dot-net.svg'
import flyway from '../assets/svg/skills/flyway.svg'
import kibana from '../assets/svg/skills/kibana.svg'
import sonarqube from '../assets/svg/skills/sonarqube.svg'
import rabbitmq from '../assets/svg/skills/rabbitmq.svg'
import kafka from '../assets/svg/skills/kafka.svg'

export const skillsImage = (skill) => {
    const skillID = skill.toLowerCase();
    switch (skillID) {
        case 'docker':
            return docker;
        case 'angular':
            return angular;
        case 'javascript':
            return javascript;
        case 'react':
            return react;
        case 'mongodb':
            return mongoDB;
        case 'postgresql':
            return postgresql;
        case 'c#':
            return csharp;
        case 'java':
            return java;
        case 'git':
            return git;
        case 'spring':
            return spring;
        case 'spring boot':
            return springBoot;
        case 'hibernate':
            return hibernate;
        case '.net':
            return dotnet;
        case 'flyway':
            return flyway;
        case 'kibana':
            return kibana;
        case 'sonarqube':
            return sonarqube;
        case 'rabbitmq':
            return rabbitmq;
        case 'kafka':
            return kafka;
        default:
            break;
    }
}