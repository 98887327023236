/* eslint-disable */
import { BiCode, BiMobileAlt, BiServer, BiPencil, BiSupport, BiTestTube, BiGitBranch } from "react-icons/bi";

export const servicesData = [
    {
        id: 2,
        title: 'Web Application Development',
        icon: <BiCode />
    },
    {
        id: 3,
        title: 'Mobile Application Development',
        icon: <BiMobileAlt />
    },
    {
        id: 6,
        title: 'API Development and Integration',
        icon: <BiServer />
    },
    {
        id: 7,
        title: 'System Architecture and Design',
        icon: <BiPencil />
    },
    {
        id: 9,
        title: 'Maintenance and Support',
        icon: <BiSupport />
    },
    {
        id: 11,
        title: 'Software Testing and Quality Assurance',
        icon: <BiTestTube />
    },
    {
        id: 13,
        title: 'DevOps and CI/CD Implementation',
        icon: <BiGitBranch />
    }
];