import React, { useState } from 'react';
import { FaPlay, FaCode } from "react-icons/fa";
import Fade from 'react-reveal/Fade';
import placeholder from '../../../assets/png/placeholder.png';
import './SingleProject.css';

function SingleProject({ id, name, desc, tags, code, demo, image, theme }) {
    const [isHovered, setIsHovered] = useState(false);

    // Extract video ID from YouTube URL
    const getYouTubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const videoId = demo ? getYouTubeId(demo) : null;

    return (
        <Fade bottom>
            <div
                key={id}
                className="singleProject"
                style={{ backgroundColor: theme.primary400 }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="projectContent">
                    <h2
                        id={name.replace(" ", "-").toLowerCase()}
                        style={{ color: theme.tertiary }}
                    >
                        {name}
                    </h2>
                    <img src={placeholder} alt={name} />
                    <div className="project--showcaseBtn">
                        <a
                            href={demo}
                            target="_blank"
                            rel="noreferrer"
                            className={`${demo ? '' : 'disabled'}`}
                            aria-labelledby={`${name.replace(" ", "-").toLowerCase()} ${name.replace(" ", "-").toLowerCase()}-demo`}
                        >
                            <FaPlay
                                id={`${name.replace(" ", "-").toLowerCase()}-demo`}
                                className="project--icon"
                                aria-label="Demo"
                            />
                        </a>
                        <a
                            href={code}
                            target="_blank"
                            rel="noreferrer"
                            className={`${code ? '' : 'disabled'}`}
                            aria-labelledby={`${name.replace(" ", "-").toLowerCase()} ${name.replace(" ", "-").toLowerCase()}-code`}
                        >
                            <FaCode
                                id={`${name.replace(" ", "-").toLowerCase()}-code`}
                                className="project--icon"
                                aria-label="Code"
                            />
                        </a>
                    </div>
                </div>
                <p
                    className="project--desc"
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary,
                    }}
                >
                    {desc}
                </p>
                <div
                    className="project--lang"
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary80,
                    }}
                >
                    {tags.map((tag, id) => (
                        <span key={id}>{tag}</span>
                    ))}
                </div>
                {videoId && (
                    <div className={`project--video-overlay ${isHovered ? 'visible' : ''}`}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${videoId}`}
                            title={name}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                )}
            </div>
        </Fade>
    );
}

export default SingleProject;