export const skillsData = [
    'Java',
    'Spring',
    'Spring Boot',
    'Hibernate',
    'C#',
    '.NET',
    'JavaScript',
    'Angular',
    'React',
    'Docker',
    'Git',
    'Flyway',
    'Kibana',
    'SonarQube',
    'RabbitMQ',
    'Kafka',
    'MongoDB',
    'PostgreSQL'
]