export const educationData = [
    {
        id: 1,
        institution: 'ENSA of Fez',
        course: 'Computer science engineering',
        startYear: '2021',
        endYear: '2024'
    },
    {
        id: 2,
        institution: 'ENSA of Fez',
        course: 'Preparatory cycle',
        startYear: '2019',
        endYear: '2021'
    },
    {
        id: 3,
        institution: 'Abdellah Chafchaouni',
        course: 'Baccalaureate : Physical Sciences',
        startYear: '2017',
        endYear: '2019'
    },
]